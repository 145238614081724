import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'side-toolbar',
  templateUrl: './side-toolbar.component.html',
  styleUrls: ['./side-toolbar.component.scss']
})
export class SideToolbarComponent implements OnInit {
  navigationScrollButton = "none";
  constructor(private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    
  }

  ngOnChanges(){
    
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.setNavigationScrollButton();
  }
  scrollFunction() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollTop > 20) ? ((sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight - 60 >= 1) ? "both" : "up") : "down";
    // this.scrollPostion=(sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight-60 >= 1) && (sidebar.scrollTop > 20);
  }

  //PMB-6554(RD) check scroll exits or not
  setNavigationScrollButton() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollHeight > sidebar.clientHeight) ? "down" : "none";

  }

  //PMB-6554(RD) set flag for up and down buttons
  handelScrollClick(direction) {
    var sidebar = document.getElementById('sidebar');
    sidebar.scrollTop = direction == "up" ? 0 : sidebar.clientHeight;
  }

}
