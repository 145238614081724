import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
// import { AppAuthService } from './app-auth.service';
// import { AlertMsgService } from './alert-msg.serivce';
import { TranslateService } from '@ngx-translate/core';
// import { AppAuthService } from './temp-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonDialogComponent } from '../modules/shared-components/components/common-dialog/common-dialog.component';
import { AppStorageService } from './app-storage.service';
// import { OneAuthService } from './one-auth.service';
import { OneAuthService } from 'one-auth';



export const skipTokenHeader = 'Authorization';
export const skipJSONContentTypeHeader = 'Content-Type';
export const skipAcceptHeader = 'Accept';

@Injectable({
    providedIn: 'root'
})

export class HttpRequestInterceptor implements HttpInterceptor {
    public dialogRef: any;
    constructor( private translate: TranslateService, private auth: OneAuthService, public snackBar: MatSnackBar, public msgDiag: MatDialog, private appStorage: AppStorageService, private router: Router ){ }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        if(request.headers.has(skipTokenHeader)){
            
            return next.handle(request);
        }
        else{
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }
        
        // if(request.headers.has(skipJSONContentTypeHeader)){
        //     request.headers.append('Content-Type', 'application/json;charset=UTF-8');
        // } 

        // if(request.headers.has(skipAcceptHeader)){
        //     request.headers.append('Accept', 'application/json;charset=UTF-8');
        // } 

        return next.handle(request).pipe(
            retry(1),
            map((event: HttpEvent<any>)=>{
                if(event instanceof HttpResponse){
                    // console.log('event: ', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse)=>{ //Need More Work
                let ae = this.translate.instant('LANG.AUTH_ERROR');
                let se = this.translate.instant('LANG.SERVICE_ERROR');
                let errmsg = "";
                // console.log(error);
                // let errordetails = {};
                // errordetails = {
                //     reason: error && error.error.error ? error.error.error: '',
                //     message: error && error.error.message ? error.error.message: '',
                //     status: error.status
                // }
                if(error.status == 200){
                    
                }
                if(error.status == 401){
                    // Auth Issue, navigate to login
                    // console.log('navigate to login');
                    let msg = { 
                        title: 'Autentication Error',
                        error: 'We were unable to Authenticate you! Please try logging in again',
                        code: 'E1004011001'
                    }
                    
                    this.openDialog(msg);
                    
                    errmsg = this.translate.instant('LANG.AUTH_ERROR_MSG') + "!";

                    // this.alert.messageAlert(ae, errmsg, ['ok']);
                    // this.navCtrl.navigateRoot('user-login');
                } else if(error.status == 4002){
                    errmsg = this.translate.instant('LANG.TOKEN_ERROR_MSG') + "!";
                    let msg = { 
                        title: 'Error',
                        error: 'There seems to be an Error! Sometimes logging in agian may resolve the issue. If the issue persists, please contact us',
                        code: 'E1004001001'
                    }
                    
                    this.openDialog(msg);
                    // this.alert.messageAlert(ae, errmsg, ['ok']);
                } else if(error.status == 405){
                        errmsg = this.translate.instant('LANG.TOKEN_ERROR_MSG') + "!";
                        // console.log('not allowed');
                        let msg = { 
                            title: 'Request Error',
                            error: 'Oops! looks like we hit a snag communicating with the service. If the issue persist please contact us!',
                            code: 'E1005001001'
                        }
                        
                        this.openDialog(msg);
                        // this.alert.messageAlert(ae, errmsg, ['ok']);
                    
                } else if(error.status == 500){
                    // Bad Request / Internal Server Error
                    errmsg = this.translate.instant('LANG.SERVICE_ERROR_MSG') + "!";
                    // console.log("Bad Request Error");
                    let msg = { 
                        title: 'Request Error',
                        error: 'Oops! looks like we hit a snag communicating with the service. If the issue persist please contact us!',
                        code: 'E1005001001'
                    }
                    
                    this.openDialog(msg);
                    // this.alert.messageAlert(se, errmsg, ['ok']);
                } else if(error.status == 1404){
                    // API Service not found
                    errmsg = this.translate.instant('LANG.SERVICE_NOTFOUND_MSG') + "!";
    
                    let msg = { 
                        title: 'Service Not Found',
                        error: 'We are having issues locating the service. If the issue persist please contact us!',
                        code: 'E1004041001'
                    }
                    
                    this.openDialog(msg);
                    // this.alert.messageAlert(se, errmsg, ['ok']);
                } else if(error.status == 502){
                    // API Service is down
                    errmsg = this.translate.instant('LANG.SERVICE_DOWN_MSG') + "!";
                    let msg = { 
                        title: 'Service Down',
                        error: 'Service is down. Please try again later!',
                        code: 'E1005021001'
                    }
                    
                    this.openDialog(msg);
                    
                } 
                return throwError(error);
               // return throwError(error);
            })
        );
    }
    

    openDialog(msg){
        if (!this.dialogRef){
            this.dialogRef = this.msgDiag.open(CommonDialogComponent, {data: msg});
        }

        
        this.dialogRef.afterClosed().subscribe(result => {
            this.dialogRef = undefined;
            if(msg.code == 'E1004011001'){
                this.auth.logoutUser();
                this.router.navigate(['login']);
            }
            if(msg.code == 'E1005021001'){
                this.auth.logoutUser();
                this.router.navigate(['login']);
            }
        });
    }


    
}