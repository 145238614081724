<div class="diag-header">
    <h2 mat-dialog-title>{{data.title}}</h2>
</div>
<mat-dialog-content>
    <div class="dialog-message">
        {{data.message}}
    </div>
</mat-dialog-content>
<mat-dialog-actions class="actions-btns">
    <button mat-raised-button *ngIf="!data.hideCancel" (click)="onCancelClick()">{{data.buttonCancelText}}</button>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>{{data.buttonOkText}}</button>
</mat-dialog-actions>