import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FileSaverService } from 'ngx-filesaver';
import { TrustDocService } from 'src/app/modules/trust-docs/services/trust-doc.service';
import { IMAGES } from '../../../../../environments/config.all';
import { CommonServicesService } from '../../services/common-services.service';

@Component({
  selector: 'doc-view-dialog',
  templateUrl: './doc-view-dialog.component.html',
  styleUrls: ['./doc-view-dialog.component.scss']
})
export class DocViewDialogComponent implements OnInit {

  public IMAGES = IMAGES;
  public categoryList: any;
  public docDetails: any;
  public docForm: FormGroup;
  public previewImage: any;
  public fileUrl: any;
  public hasPreview: boolean;
  public previewLoading: boolean;
  public docViewDetails: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DocViewDialogComponent>, private docService: TrustDocService, private fb: FormBuilder, public sanitizer: DomSanitizer, private fileSaverService: FileSaverService, private commonServicesService: CommonServicesService) { }

  ngOnInit() {
    console.log("hi",this.data)
    this.hasPreview = false;
    this.previewLoading = true;
      this.docViewDetails = this.data;
    this.getDocumentDetails();
    this.getDocumentView();
  }

  getDocumentView() {
    let objectId = this.docViewDetails.csObjectId;
    this.docService.getDocumentPreview(objectId).subscribe((res) => {
      this.previewImage = res;
      let blob = this.b64toBlob(res.content);
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      let pat = /(?:\.([^.]+))?$/;
      let ext = pat.exec(this.docViewDetails.documentName)[1];
      if (ext != undefined && (ext == "png" || ext == "gif" || ext == "jpg" || ext == "jpeg" || ext == "bmp")) {
        this.hasPreview = true;
      } else {
        this.hasPreview = false;
      }
      this.previewLoading = false;
    });

  }

  getDocumentDetails() {
    let reqData =
    {
      "programId": this.docViewDetails.programId,
      "targetOrgId": this.docViewDetails.targetOrgId,
      "documentUuid": this.docViewDetails.documentUUID
    };
    this.commonServicesService.getDocumentDetails(reqData).subscribe((res) => {
      if (res) {
        this.docDetails = res[0];
      }
    });
  }

  validateOrReject(status) {
    let reqData =
    {
      "docDefDocId": this.docDetails.docDefDocId,
      "docStatusCode": status
    };
    this.commonServicesService.validateOrReject(reqData).subscribe((res) => {
        this.dialogRef.close();
    });
  }

  // DownloadFile() {
  //   let blob;
  //   let filename = this.docDetails.documentName;
  //   let objectId = this.docDetails.csObjectId;
  //   this.docService.downloadDocument(objectId).subscribe((res) => {
  //     blob = this.b64toBlob(res.base64content);
  //     this.fileSaverService.save((<any>blob), filename);
  //   })
  // }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}