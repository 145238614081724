import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from '../../../../environments/config.url';

@Injectable({
  providedIn: 'root'
})
export class TrustDocService {

  private header: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) { 
  //  this.header.set('Content-Type', 'application/json');
  }

  getDocsList(data: any): Observable<any>{
    let url = URLS.GetDocsListAPI;
    // data = {"id": 2};
    return this.http.post<any>(url, data, { headers: this.header, observe: 'response' }).pipe(
      map((res: any)=>{
        // console.log(res);
        if(res.headers && res.status == 200){
          return res.body;
        }
      })
    );
  }

  getReceivedDocsList(data: any): Observable<any>{
    let url = URLS.GetReceivedDocsListAPI;
    // let d = { }
    return this.http.post<any>(url, data, { headers: this.header, observe: 'response' }).pipe(
      map((res: any)=>{
        // console.log(res);
        if(res.headers && res.status == 200){
          return res.body;
        }
      })
    );
  }

  getDocSharedWithList(data: any): Observable<any>{
    let url = URLS.GetDocSharedWithListAPI;
    return this.http.post<any>(url, data, { headers: this.header, observe: 'response'}).pipe(
      map((res: any)=>{
        // console.log(res);
        if(res.headers && res.status == 200){
          return res.body;
        }
      })
    );
  }

  getAllCategories(): Observable<any>{
    let url = URLS.GetDocCategoriesListAPI;
    return this.http.get(url).pipe(
      map((res: any)=>{
        return res;
      })
    );
  }
  
  verifyDocumentHash(data: any): Observable<any>{
    let url = URLS.VerifyDocumentAPI;
    return this.http.post<any>(url, data, {headers: this.header, observe: 'response'}).pipe(
      map((res: any)=>{
        if(res.headers && res.status == 200){
          return res.body;
        }
      })
    );
  }

  addDocumentPermission(data): Observable<any>{
    let url = URLS.DocAddShareOrgAPI;
    return this.http.post(url, data, {headers: this.header, responseType: 'text'}).pipe(
      map((res: any)=>{
        // if(res.headers && res.status == 200){
        //   return res.body;
        // }
        return res;
      })
    );
  }

  removeDocumentPermission(data): Observable<any>{
    let url = URLS.DocRemoveShareOrgAPI;
    return this.http.post(url, data, {headers: this.header, responseType: 'text'}).pipe(
      map((res: any)=>{
        // if(res.headers && res.status == 200){
        //   return res.body;
        // }
        console.log(res)
        return res;
      })
    );
  }

  getSuggestOrgs(srchtxt): Observable<any>{
    let url = URLS.SuggestOrgAPI +  srchtxt;
    return this.http.get(url).pipe(
      map((res: any)=>{
        return res
      })
    )
    // return this.http.get<any>(url, srchtxt, {headers: this.header, observe: 'response'}).pipe(
    //   map((res: any)=>{
    //     if(res.headers && res.status == 200){
    //       return res.body;
    //     }
    //   })
    // )
  }

  getAllOrgsList(orgids): Observable<any>{
    let url = URLS.GetAllOrgsAPI + orgids;
    return this.http.get(url).pipe(
      map((res: any)=>{
        return res;
      })
    )
  }

  getDocumentPreview(objectId: string): Observable<any>{
    let url = URLS.DocumentViewFromStoreAPI + objectId;
    return this.http.get<any>(url).pipe(
      map((res: any)=>{
        return res;
      })
    )
  }

  downloadDocument(objectId: string): Observable<any>{
    
    this.header.set('Content-Type', 'application/octet-stream'); //TOOD Test
    this.header.append('Accept', 'application/octet-stream');
    console.log(this.header);
    let url = URLS.DocumentDownloadFromStoreAPI + objectId;
    return this.http.get(url, {headers: this.header}).pipe(
      map((res: any)=>{
        console.log(res);
        return res;
      })
    )
  }

  getProgramListToAssign(docType): Observable<any> {
    let url = URLS.GetProgramListToAssignAPI + docType;
    return this.http.get(url).pipe(
        map((res: any) => {
            return res;
        })
    );
  }

  assignProgram(data): Observable<any> {
    let url = URLS.AssignProgramAPI;
    return this.http.post(url, data, { headers: this.header, observe: 'response' }).pipe(
      // return this.http.post(url, data, { headers: this.header, responseType: 'text' }).pipe(
        map((res: any) => {
            return res.body;
            // return res;
        })
    );
}

}
