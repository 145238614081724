import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TrustDocService } from '../modules/trust-docs/services/trust-doc.service';

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  public categories: any;
  public user: any;

  constructor(private localStorage: StorageMap, private docService: TrustDocService) { }

  loadMeta() {
    // this.initializeCategories();
    this.initializeDocTypes();
  }

  loadCategoriesAsync(): Promise<any> {
    return new Promise((resolve, reject) => {
      //check if availble in storage
      this.localStorage.get('categories').subscribe((cat) => {
        console.log(cat);
        // if(cat !== undefined && cat !== null){
        if (cat) {
          this.categories = cat;
          resolve(true);
        } else {
          // not in storage, fetching from service
          this.docService.getAllCategories().subscribe((res: any) => {
            console.log(res)
            // if(res !== undefined && cat !== null){
            if (res) {
              this.categories = [];
              // res.data.forEach((v) => {
              res.forEach((v) => {
                this.categories[v.code] = v.name;
              });
              console.log(this.categories);
              this.localStorage.set('categories', this.categories).subscribe((data) => {
                resolve(true);               
              });
            }
            else {
              reject();
            }
          });
        }
      });
    });
  }

  initializeCategories() {
    if (this.categories == null || this.categories == undefined || this.categories.length < 1) {
      //check if availble in storage
      this.localStorage.get('categories').subscribe((cat) => {
        console.log("hi..", cat)
        if (cat !== undefined && cat !== null) {
          this.categories = cat;
        } else {
          // not in storage, fetching from service
          // console.log('fetch from service');
          this.docService.getAllCategories().subscribe((res: any) => {
            console.log(res);
            if (res !== undefined && cat !== null) {
              this.categories = [];
              // res.data.forEach((v) => {
              res.forEach((v) => {
                this.categories[v.code] = v.name;
              });
              this.localStorage.set('categories', this.categories).subscribe((data) => {
                return this.categories;
              });
            }
            else {
              // silence
            }
          });
        }
      }, (err) => {
        // console.log('no categories');
        this.docService.getAllCategories().subscribe((res: any) => {
          if (res !== undefined && res !== null) {
            this.categories = [];
            // res.data.forEach((v) => {
            res.forEach((v) => {
              this.categories[v.code] = v.name;
            });
            this.localStorage.set('categories', this.categories).subscribe((data) => {
              return this.categories;
            });
          }
          else {
            // silence
          }
        });
      });
    } else {
      return this.categories;
    }
  }

  initializeDocTypes() {
    this.docService.getAllCategories().subscribe((res: any) => {
      console.log(res);
      if (res !== undefined) {
        this.categories = [];
        res.forEach((v) => {
          this.categories[v.code] = v.name;
        });
        this.localStorage.set('categories', this.categories).subscribe((data) => {
          return this.categories;
        });
      }
      else {
        // silence
      }
    });
  }

  getCategories() {
    if (this.categories !== undefined && this.categories !== null) {
      return this.categories;
    } else {
      console.log('cat error');
    }

  }

}
