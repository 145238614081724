import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
// import { CommonNotificationsComponent } from './components/common-notifications/common-notifications.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';
// import { AppStorageService } from '../../services/app-storage.service';
// import { AppAuthService } from './services/temp-auth.service';
// import { HttpRequestInterceptor } from '../../services/http-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DocViewDialogComponent } from './components/doc-view-dialog/doc-view-dialog.component';
import { ProcurantLoadingComponent } from './components/procurant-loading/procurant-loading.component';
import { CommonServicesService } from './services/common-services.service';
import { DialogBoxService } from './services/dialog-box.service';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';



@NgModule({
    declarations: [
        CommonDialogComponent,
        CommonSnackbarComponent,
        CommonNotificationComponent,
        ProcurantLoadingComponent,
        ConfirmDialogComponent,
        DocViewDialogComponent,
        DatePickerComponent,
        MultiSelectComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialUiModule,
        TranslateModule
    ],
    providers: [
        DialogBoxService,
        CommonServicesService,
        DatePipe
    ],
    exports: [
        CommonDialogComponent,
        ProcurantLoadingComponent,
        DatePickerComponent,
        MultiSelectComponent
    ]
})
export class SharedComponentsModule { }
