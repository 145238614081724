<side-nav>
    <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
    <div class="navBarHeight" sidenavitem>
        <div class="nav-new-bar">
            <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
                (click)="handelScrollClick('up')">
                <img src="../../../../../assets/images/up_double.png" alt="">
            </div>
            <div class="nav-menu-list" id="sidebar" (scroll)="scrollFunction()">
                <a mat-list-item routerLink="/dashboard" routerLinkActive="active"
                    state="{data: {'linktitle': 'Dashboard')}" title="{{'SIDEMENU.LABEL.DASHBOARD' | translate}}">
                    <!-- <mat-icon>dashboard</mat-icon> -->
                    <div class="sprite-container dashboard">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'SIDEMENU.LABEL.DASHBOARD' | translate }}</span>
                </a>
                <a mat-list-item routerLink="/score-card" routerLinkActive="active"
                    state="{data: {'linktitle': 'Score Card')}" title="{{'SIDEMENU.LABEL.SCORECARD' | translate}}">
                    <!-- <mat-icon>show_chart</mat-icon> -->
                    <div class="sprite-container score">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'SIDEMENU.LABEL.SCORECARD' | translate }}</span>
                </a>
                <a mat-list-item routerLink="/instructions" routerLinkActive="active"
                    state="{data: {'linktitle': 'Instructions')}" title="{{'SIDEMENU.LABEL.INSTRUCTIONS' | translate}}">
                    <!-- <mat-icon>note_add</mat-icon> -->
                    <div class="sprite-container instructions">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'SIDEMENU.LABEL.INSTRUCTIONS' | translate }}</span>
                </a>
                <a mat-list-item routerLink="/reports" routerLinkActive="active"
                state="{data: {'linktitle': 'Reports')}" title="{{'SIDEMENU.LABEL.REPORTS' | translate}}">
                    <div class="sprite-container report">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'SIDEMENU.LABEL.REPORTS' | translate }}</span>
                </a>
                <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
                    (click)="handelScrollClick('down')">
                    <img src="../../../../../assets/images/down_double.png" alt="">
                </div>
            </div>

        </div>
        <div class="connect-link">
            <a mat-list-item routerLink="/connect" target="_blank" routerLinkActive="active"
                title="{{'NAVBAR.LABEL.CONNECT' | translate}}" class="connect-link">
                <!-- <span class="sidebar-icon icon-Group"></span> -->
                <img src="../../../../../assets/images/Group.png">
                <span class="nav-name">{{'SIDEMENU.LABEL.CONNECT' | translate}}</span>
            </a>
        </div>
    </div>

</side-nav>