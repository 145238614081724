import { environment } from './environment';

export const URLS = Object();

URLS.GetAllOrgsAPI = environment.BASE_API_URL + "admin/api/org/all-orgs-by-ids"; // TBD
URLS.SuggestOrgAPI = environment.BASE_API_URL + "admin/api/org/search-active-suggest/";

URLS.UserLoginServiceAPI = environment.BASE_API_URL + "login";
URLS.UserLogoutServiceAPI = environment.BASE_API_URL + "api/auth/logout";
URLS.UserInfoServiceAPI = environment.BASE_API_URL + "auth/api/user/info";
URLS.UserPermissionAPI = environment.BASE_API_URL + "auth/api/user/user-app-module-permissions";

URLS.DocumentUploadedDataAPI = environment.BASE_API_URL + "docshare/api/documents/dashboard/documentUploaded";
URLS.DocumentReceivedDataAPI = environment.BASE_API_URL + "docshare/api/documents/dashboard/documentReceived";
URLS.SharingDetailsDataAPI = environment.BASE_API_URL + "docshare/api/documents/dashboard/sharingDetail";
URLS.AgingReportDataAPI = environment.BASE_API_URL + "docshare/api/documents/dashboard/agingReport";

URLS.DocumentViewFromStoreAPI = environment.BASE_API_URL + "admin/api/object-store/view/";
URLS.DocumentDownloadFromStoreAPI = environment.BASE_API_URL + "admin/api/object-store/";// "admin/api/object-store/download/";


URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-ApplicationModules";

URLS.GetOrgBusinessTypeAPI = environment.BASE_API_URL + "admin/api/org/public/business-types";

URLS.CountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.StateListAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";


URLS.GetAllOrgsListAPI = environment.BASE_API_URL + "admin/api/org/all-orgs";
URLS.GetPOFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";

//Dashboard
URLS.FetchCommodityReportAPI = environment.BASE_API_URL + "inspect/api/reports/fetch-commodity-report";
URLS.FetchAvgScoreCommodityAPI = environment.BASE_API_URL + "inspect/api/reports/fetch-avg-score-for-commodity";
URLS.FetchStatusCountsCommodityAPI = environment.BASE_API_URL + "inspect/api/reports/fetch-status-counts-for-commodity";
URLS.ShipsToBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/DELIVERY";
URLS.ActiveCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/fetch-active-org-commodities";
URLS.GetOnlineOfflineSupplierListAPI = environment.BASE_API_URL + "admin/api/trading-partners/get-online-offline-suppliers/BUYER/SUPPLIER";
URLS.GetTopVendorAPI = environment.BASE_API_URL + "inspect/api/reports/fetch-top-vendors-for-commodities";
URLS.GetBottomVendorAPI = environment.BASE_API_URL + "inspect/api/reports/fetch-bottom-vendors-for-commodities";
URLS.GetVendorAvgScoreAPI = environment.BASE_API_URL + "inspect/api/reports/fetch-vendor-avg-score-by-commodities";
URLS.GetVendorAvgStatsAPI = environment.BASE_API_URL + "inspect/api/reports/fetch-vendor-avg-stats";

//Instrictions
URLS.AddUpdateInstruction = environment.BASE_API_URL + "inspect/api/commodity/addUpdateInstruction";
URLS.GetInstructionList = environment.BASE_API_URL + "inspect/api/commodity/instruction/list";
URLS.GetInstructionDetails = environment.BASE_API_URL + "inspect/api/commodity/fetch/instruction/";
URLS.GetFilterListAPI = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";
URLS.SaveFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/save/filter";

//(NM)PMB-8531 Added URLS for inspection report
//Reports  
URLS.GetInspectionDetailsReport = environment.BASE_API_URL + "inspect/api/reports/fetch-inspectionDetails-report";
URLS.DownloadInspectionDetailsReport = environment.BASE_API_URL + "inspect/api/reports/download/inspection-details";
