<form [formGroup]="parentForm">
  <!-- <mat-form-field class="From w-180" appearance="{{fieldStyle}}"> -->
  <mat-form-field class="date-picker-cust" appearance="{{fieldStyle}}">
    <mat-label *ngIf="showLabel">{{ label }}</mat-label>
  <input matInput id="{{ID}}" [matDatepicker]="dp" [min]="minDate" (click)="dp.open()" [max]="maxDate"
    placeholder="{{placeholder}}" formControlName="{{field}}" (dateChange)="dateChangeEvent($event.target.value)">
  <mat-datepicker #dp></mat-datepicker>
  <mat-datepicker-toggle matSuffix [for]="dp">
    <mat-icon matSuffix matDatepickerToggleIcon (click)="dp.open()">calendar_today</mat-icon>
  </mat-datepicker-toggle>
  </mat-form-field>
</form>
