import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'common-snackbar',
  templateUrl: './common-snackbar.component.html',
  styleUrls: ['./common-snackbar.component.scss']
})
export class CommonSnackbarComponent implements OnInit {

  public errdata: any;
  constructor( 
    public snackBarRef: MatSnackBarRef<CommonSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    this.errdata = JSON.parse(this.data);
    
  }

}
