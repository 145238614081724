
/***********************************************************************
* DESCRIPTION :

*  To Display Inspection Details Report

* NOTES :

* AUTHOR :    Nikita Morankar        CREATE DATE :    25 April 2024

* CHANGES :

* REF NO          VERSION   DATE         WHO     DETAIL

* PMB-8531        2.6.0     25/04/2024   NM     Added Inspection Details report in report 

************************************************************************/
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }
  @Input() parentForm: FormGroup;
  @Input() field: any;
  @Input() listData: any;
  @Input() set disabled(condition: boolean) {
    const action = condition != null && condition ? 'disable' : 'enable';
    this.parentForm.controls[this.field][action]();
  }
  @Input() isShowAllOption: boolean = true;
  @Input() valuetoShow: string;
  @Input() valuetoBind: any;
  @Input() placeholder: string;
  @Input('isDirty') isDirty: boolean;
  @Output() onListChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedList = [];
  @Input() allSelected: boolean;
  @Input() requiredError: string;
  @Input() clearSelect: boolean = false;
  //@Output() clearSelected: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  clearSelectedField() {
    this.parentForm.controls[this.field].setValue([]);
    this.selectedList = [];
    this.allSelected = false;
  }

  toggleSelect(event) {
    console.log('t ', event)
    this.selectedList = [];
    let a = (event.value instanceof Array) ? (event.value.filter(t => t == undefined).length > 0) ? false : true : true
    if (event.value && a) {
      event.value.forEach(element => {
        this.selectedList.push(element);
      });
    }
    if (this.selectedList.length === this.listData.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    this.onListChanged.emit(this.selectedList);
  }

  masterToggle(event) {
    this.selectedList = [];
    if (event.checked) {
      this.listData.forEach(element => {
        if (this.valuetoBind) {
          this.selectedList.push(element[this.valuetoBind]);
          this.parentForm.controls[this.field].patchValue([...this.listData.map(item => item[this.valuetoBind])]);
        } else {
          this.selectedList.push(element);
          this.parentForm.controls[this.field].patchValue([...this.listData.map(item => item)]);
        }
      });
    } else {
      this.selectedList = [];
      this.parentForm.controls[this.field].patchValue([]);
    }
    if (this.selectedList.length === this.listData.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    this.onListChanged.emit(this.selectedList);
  }
  
  ngAfterContentChecked(): void {
    if (this.isDirty) {
      this.parentForm.controls[this.field].markAsTouched()
    }
  }
}
