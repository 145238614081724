import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TrustDocService } from './modules/trust-docs/services/trust-doc.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AppStorageService } from './services/app-storage.service';
// import { AppAuthService } from './services/temp-auth.service';
// import { OneAuthService } from './services/one-auth.service';
import { OneAuthService } from 'one-auth';
import { InitLoaderService } from './services/init-loader.service';

// import { AuthHelper } from './services/auth-helper';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private page: string;
  public isLogin: boolean = false;
  public isAppSelector = false;
  constructor(private auth: OneAuthService,public init:InitLoaderService, public translate: TranslateService, public router: ActivatedRoute, private docService: TrustDocService, private appStorage: AppStorageService, public route: Router){
    this.translate.setDefaultLang('en_US');
    this.init.load();
  }

  ngOnInit(){
    if(this.auth.user.info.id === undefined){
      this.auth.LoadAuth().then(()=>{
        this.appStorage.loadMeta();
      });
    }

    this.route.events.subscribe((pat) => {
      if (this.route.url === "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.route.url === "/logout") {
        if(!this.isLogin){
          this.isLogin = true;
        }
      } else if(this.route.url !== "/BBF65054CC9C2C8CB6692B2A1648FD8C" && this.route.url !== "/logout") {
        if(this.isLogin){
          this.isLogin = false;
        }
      }
    });


    // this.route.events.subscribe((pat)=>{
    //   if(this.route.url === "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.route.url === "/logout"){
    //     this.isLogin = true;
    //   } else{
    //     this.isLogin = false;
    //   }

    // });

    // this.auth.checkLoginStatus().then((r)=>{
    //   console.log(r);
    // });

    // this.route.events.subscribe((pat)=>{
    //   if(this.route.url === "/login" || this.route.url === "/logout" || this.route.url === "/apps"){
    //     this.isLogin = true;
    //     if(this.route.url === "/apps"){
    //       this.isAppSelector = true;
    //     } else{
    //       this.isAppSelector = false;
    //     }
    //   } else{
    //     this.isLogin = false;
    //   }



    // })



    //this.translate.use('en_US');
    //load categoi
    // this.authService.loadAuth();
  }
}
