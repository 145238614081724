<div class="ethereum-logo" *ngIf="docDetails?.blockchainStored">
    <label>{{ 'INSPECT.LABEL.CERTIFIED_ON' | translate}}</label>
    <img [src]="IMAGES.ETHEREUM_LOGO" alt="Ethereum" />
</div>
<h2 mat-dialog-title>{{ 'PROGRAM.LABEL.DOCUMENT_DETAILS' | translate }}</h2>
<mat-dialog-content>
    <div class="document-container">
        <div class="document-view">
            <!-- Document Images Here -->
            <div *ngIf="(!previewLoading); Else loading">
                <img class="document" *ngIf="(hasPreview); Else noPreview"
                    [src]="sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,'+previewImage?.content)" />
            </div>
        </div>


        <div class="document-details">
            <div class="filenamelabel">
                {{ docDetails?.documentName }}
            </div>

            <div class="details-list">
                <div class="details-list-item">
                    <!-- <div class="details-label">{{ 'INSPECT.LABEL.CERTIFIED_ON' | translate}}</div> -->
                    <div class="details-label">{{ 'PROGRAM.LABEL.DATE_ADDED' | translate }}</div>
                    <div class="details-desc" *ngIf="docDetails?.docDefDocCreatedTs !== null; else NotAvailalbe">
                        {{ docDetails?.docDefDocCreatedTs | date:'MM/dd/yyyy' }}</div>
                </div>
                <div class="details-list-item">
                    <div class="details-label">{{ 'INSPECT.LABEL.DOCUMENT_TYPE' | translate}}</div>
                    <div class="details-desc" *ngIf="docDetails?.documentTypeCode !== null; else NotAvailalbe">
                        {{ docDetails?.documentTypeCode }}</div>
                </div>
                <div class="details-list-item">
                    <div class="details-label">{{ 'INSPECT.LABEL.EFFECTIVE_DATE' | translate}}</div>
                    <div class="details-desc" *ngIf="docDetails?.effectiveDate !== null; else NotAvailalbe">
                        {{ docDetails?.effectiveDate | date:'MM/dd/yyyy'}}</div>
                </div>
                <div class="details-list-item">
                    <div class="details-label">{{ 'INSPECT.LABEL.EXPIRATION_DATE' | translate}}</div>
                    <div class="details-desc" *ngIf="docDetails?.expirationDate !== null; else NotAvailalbe">
                        {{ docDetails?.expirationDate | date:'MM/dd/yyyy'}}</div>
                </div>
                <div class="details-list-item">
                    <!-- <div class="details-label">{{ 'INSPECT.LABEL.TX_STATUS' | translate}}</div> -->
                    <div class="details-label">Status</div>
                    <div class="details-desc" *ngIf="docDetails?.bcStatusCode !== null; else NotAvailalbe">
                        {{ docDetails?.bcStatusCode | lowercase }}</div>
                </div>
                <div class="details-list-item">
                    <div class="details-label">{{ 'INSPECT.LABEL.BLOCKCHAIN_NAME' | translate}}</div>
                    <div class="details-desc" *ngIf="docDetails?.blockchainName !== null; else NotAvailalbe">
                        {{ docDetails?.blockchainName }}</div>
                </div>

                <div class="details-list-item full">
                    <div class="details-label">{{ 'INSPECT.LABEL.DOCUMENT_HASH' | translate}}</div>
                    <div class="details-desc" *ngIf="docDetails?.dataHash !== null; else NotAvailalbe">
                        {{ docDetails?.dataHash }}</div>
                </div>
                <div class="details-list-item full">
                    <div class="details-label">{{ 'INSPECT.LABEL.TRANSACTION_HASH' | translate}}</div>
                    <div class="details-desc" *ngIf="docDetails?.txHash !== 'undefined'; else NotAvailalbe">
                        {{ docDetails?.txHash }}</div>
                </div>

                <div class="details-list-item full">
                    <div class="details-label">{{ 'INSPECT.MESSAGE.RINKBY_VERIFICATION_LINK' | translate}}<span
                            class="link-caption">{{ 'INSPECT.MESSAGE.CLICK_TO_OPEN_NEW_WINDOW' | translate}}</span>
                    </div>
                    <div class="details-desc linkdesc" *ngIf="docDetails?.txHash !== 'undefined'; else NotAvailalbe">
                        <a href="https://rinkeby.etherscan.io/tx/{{docDetails?.txHash}}" target="_new">
                            https://rinkeby.etherscan.io/tx/{{docDetails?.txHash}}
                        </a>
                    </div>
                </div>

                <div class="details-list-item full">
                    <div class="details-label">{{ 'PROGRAM.LABEL.LINKED_TO_PROGRAMS' | translate }}</div>
                    <div class="details-desc">
                        <div class="linked-program-list">
                            <mat-list>
                                <mat-list-item *ngFor="let item of docDetails?.programs">
                                    {{item.name}}
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <!-- <button mat-stroked-button (click)="DownloadFile()" mat-flat-button
        color="primary">{{ 'BUTTON.DOWNLOAD' | translate}}</button> -->
    <button *ngIf="docViewDetails.isVerify" mat-stroked-button mat-flat-button color="primary"
        (click)="validateOrReject('VERIFIED')">{{ 'PROGRAM.LABEL.VALIDATE' | translate }}</button>
    <button *ngIf="docViewDetails.isVerify" mat-stroked-button mat-flat-button color="primary"
        (click)="validateOrReject('REJECTED')">{{ 'PROGRAM.LABEL.REJECT' | translate }}</button>
    <button mat-stroked-button mat-dialog-close (click)="closeDialog()">{{ 'BUTTON.CLOSE' | translate}}</button>

</mat-dialog-actions>

<ng-template #NotAvailalbe>
    <div class="details-desc">{{ 'INSPECT.LABEL.NA' | translate}}</div>
</ng-template>

<ng-template #noPreview>
    <div class="nopreview">
        {{ 'INSPECT.MESSAGE.PREVIEW_CURRENTLY_NOT_AVAILABLE' | translate}}
    </div>
</ng-template>

<ng-template #loading>
    <procurant-loading></procurant-loading>
</ng-template>