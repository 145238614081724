import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OneAuthService } from 'one-auth';
import { IMAGES } from 'src/environments/config.all';

@Component({
  selector: 'dev-login',
  templateUrl: './dev-login.component.html',
  styleUrls: ['./dev-login.component.scss']
})

export class DevLoginComponent implements OnInit {
  private token: string;
  public showLogin: boolean = false;
  public showWaitLogo: boolean = true;
  loginForm: FormGroup;
  loginError: boolean = false;
  apps: any;
  public IMAGES = IMAGES;

  public user: any;
  private ecryptsec: string;
  private header: HttpHeaders = new HttpHeaders();
  constructor(public auth: OneAuthService, public dialog: MatDialog, private fb: FormBuilder, public translate: TranslateService, private http: HttpClient, private router: Router) {
    this.header.set('Content-Type', 'application/json');
    this.translate.setDefaultLang('en_US');
    this.loginForm = fb.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    let transwait;
    // this.translate.use('en_US');
    this.showWaitLogo = true;
    this.showLogin = false;
    this.auth.checkLoginStatus().then((res) => {
      transwait = setTimeout(() => {
        this.showWaitLogo = false;
        if (res) {
          this.router.navigate(['dashboard']);
        } else {
          this.showLogin = true;
        }
      }, 2000);

    }, (err) => {

      let logoblurout = setTimeout(() => {
        this.showWaitLogo = false;
        let loginblurin = setTimeout(() => {
          this.showLogin = true;
        }, 1200);
      }, 1500);
    })
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  login() {
    let un = this.loginForm.get("username").value;
    let up = this.loginForm.get("password").value;
    if (un !== undefined && un !== "" && up !== undefined && up !== "") {
      this.auth.validate(un, up).subscribe(res => {
        this.auth.getUserInfoPermission().subscribe((ipres) => {
          if(ipres){
            if (this.auth.user.info.locale !== undefined) {
              this.translate.use(this.auth.user.info.locale);
            } else {
              this.translate.use('en_US');
            }
            this.router.navigate(['dashboard']);
          }
        });
      });
          // this.auth.checkUserAuthValidity(false).subscribe((res) => {
          //   if (this.auth.user.info.locale !== undefined) {
          //     this.translate.use(this.auth.user.info.locale);
          //   } else {
          //     this.translate.use('en_US');
          //   }
          //   this.router.navigate(['dashboard']);
            // if(this.auth.user.permissions.length <= 1){
            //   //Everything checks out, has single app acess,redirecting directly to app to avoid flicker
            //   window.location.href = this.auth.user.permissions[0].appLink;
            // } else{
            //   this.router.navigate(['apps']);
            // }
          // }, (err) => {
          // });
      //   }, (err) => { });
      // }, (err) => { });
    } else {
      //wait for user to do the right thing
    }


  }

  // openRegistrationDialog() {
  //   let dialogRef = this.dialog.open(CompanyRegistrationComponent, {
  //     height: '85vh',
  //     disableClose: false,

  //   });
  //   dialogRef.afterClosed().subscribe(result => {

  //   });
  // }

}
