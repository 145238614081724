import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { RedirectGuard } from './services/redirect-guard';
import { DevLoginComponent } from './modules/dev-login/components/dev-login/dev-login.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'docs',
    loadChildren: () => import('./modules/trust-docs/trust-docs.module').then(m => m.TrustDocsModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard-report/dashboard-report.module').then(m => m.DashboardReportModule)
  },
  {
    path: 'score-card',
    loadChildren: () => import('./modules/score-card/score-card.module').then(m => m.ScoreCardModule)
  },
  {
    path: 'instructions',
    loadChildren: () => import('./modules/instructions/instructions.module').then(m => m.InstructionsModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports-analytics/reports-analytics.module').then(m => m.ReportsAnalyticsModule) //(NM)PMB-8531 Added new module reports for inspection report
  },
  {
    path: 'login',
    component: RedirectGuard,
    canActivate: [RedirectGuard], 
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/login"
    }
  },
  {
    path: 'logout',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/logout"
    }
  },
  {
    path: 'apps',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/apps"
    }
  },
  {
    path: 'connect',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'connect' + environment.DOMAIN + '/industry-analytics?tab=true'
    }
  },
  {
    path: "BBF65054CC9C2C8CB6692B2A1648FD8C",
    component: DevLoginComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
